import type { ComponentPropsWithoutRef } from 'react';
import { Link, useLocation } from '@remix-run/react';

/**
 * Wrapper around link that preserves the search query string when navigating between pages
 *
 * to should be the path to navigate to, without any additional query string
 * @param props
 * @param omit - an array of query string keys to omit from the preserved query string
 * @constructor
 */
export function PreserveQueryLink({
  omit = [],
  ...props
}: ComponentPropsWithoutRef<typeof Link> & { to: string; omit?: string[] }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  omit.forEach((key) => searchParams.delete(key));

  const to = props.to + '?' + searchParams.toString();

  return (
    <Link {...props} to={to}>
      {props.children}
    </Link>
  );
}
